<template>
    <div class="p-5 h-full">

        <div class="relative flex h-full flex-col">
            <div class="flex-grow h-full w-full mx-auto lg:flex">
                <!-- main wrapper -->
                <div class="flex-1 min-w-0 rounded-lg bg-white xl:flex xl:flex-col">
                    <!-- Main Window -->
                    <div  v-if="session.status !== null" class="bg-white rounded-l-lg lg:min-w-0 lg:flex-1 flex flex-col">
                        <div class="pl-4 pr-6 pt-4 pb-4 border-b border-t border-gray-200 sm:pl-6 lg:pl-8 xl:pl-6 xl:pt-6 xl:border-t-0 flex flex-col 3xl:flex-row justify-between">
                            <div class="flex w-full 3xl:w-1/3 justify-start">
                                <div class="flex items-center">
                                    <profile-image-with-pop-card :prop-user="session.groupSession.initiator"></profile-image-with-pop-card>
                                    <h1 class="ml-3 flex-1 text-lg leading-7 font-medium">{{ session.groupSession.initiator.firstName + " " + session.groupSession.initiator.lastName}}</h1>
                                    <span class="ml-2 text-cool-gray-700">(organizer)</span>
                                </div>
                            </div>

                            <div class="flex w-full 3xl:w-1/3 justify-start 3xl:justify-center">
                                <div v-if="session.groupSession.sessionType === 'community'" class="flex items-center">
                                    <nav class="hidden sm:flex" aria-label="Breadcrumb">
                                        <ol class="flex items-center space-x-4">
                                            <li>
                                                <div>
                                                    <router-link tag="a" :to="`/${communityOwner}`" class="text-lg font-medium text-indigo-600 hover:text-indigo-800">{{ communityOwner }}</router-link>
                                                </div>
                                            </li>
                                            <li>
                                                <div class="flex items-center">
                                                    <svg class="flex-shrink-0 h-5 w-5 text-gray-400" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor" aria-hidden="true">
                                                        <path fill-rule="evenodd" d="M7.293 14.707a1 1 0 010-1.414L10.586 10 7.293 6.707a1 1 0 011.414-1.414l4 4a1 1 0 010 1.414l-4 4a1 1 0 01-1.414 0z" clip-rule="evenodd" />
                                                    </svg>
                                                    <router-link tag="a" :to="`/${communityOwner}/${communityNameForUrl}/groupSessions`" class="ml-4 text-lg font-medium text-indigo-600 hover:text-indigo-800">{{ communityName }}</router-link>
                                                </div>
                                            </li>
                                        </ol>
                                    </nav>
                                </div>
                            </div>
                            <div class="flex w-full 2xl:w-1/3 justify-end">
                                <div v-if="isInCall" class="">
                                    <div class="inline-flex rounded-md shadow">
                                        <button  @click="leaveSession"  class="inline-flex items-center justify-center px-5 py-2 border border-transparent text-base leading-6 font-medium rounded-md text-white bg-red-600 hover:bg-red-500 active:bg-red-600 focus:outline-none focus:shadow-outline transition duration-150 ease-in-out">
                                            Leave session
                                        </button>
                                    </div>
                                </div>
                            </div>

                        </div>
                        <div ref="dailyFrame" class="w-full flex-grow flex items-center justify-center">
                            <div v-if="!isInCall" class="">
                                <div class="bg-white">
                                    <div v-if="!tooLate">
                                        <div v-if="session.status === 'invited'" class="max-w-screen-xl mx-auto text-center py-12 px-4 sm:px-6 lg:py-16 lg:px-8">
                                            <h2 class="text-3xl leading-9 font-extrabold tracking-tight text-gray-900 sm:text-4xl sm:leading-10">
                                                You've been invited
                                            </h2>
                                            <div class="mt-8 flex justify-center">
                                                <div class="inline-flex rounded-md shadow">
                                                    <button @click="attendSession"  class="inline-flex items-center justify-center px-5 py-3 border border-transparent text-base leading-6 font-medium rounded-md text-white bg-indigo-600 hover:bg-indigo-500 active:bg-indigo-600 focus:outline-none focus:shadow-outline transition duration-150 ease-in-out">
                                                        Attend the session
                                                    </button>
                                                </div>
                                                <div class="ml-3 inline-flex rounded-md shadow">
                                                    <button  class="inline-flex items-center justify-center px-5 py-3 border border-transparent text-base leading-6 font-medium rounded-md text-white bg-red-600 hover:bg-red-500 active:bg-red-600 focus:outline-none focus:shadow-outline transition duration-150 ease-in-out">
                                                        Decline invitation
                                                    </button>
                                                </div>
                                            </div>
                                        </div>
                                        <div v-if="session.status !=='attending' && session.groupSession.sessionType === 'community'">
                                            <div v-if="isGoodTimeToJoinSession"  class="max-w-screen-xl mx-auto text-center py-12 px-4 sm:px-6 lg:py-16 lg:px-8">
                                                <h2 class="text-3xl leading-9 font-extrabold tracking-tight text-gray-900 sm:text-4xl sm:leading-10">
                                                    Be a part of the conversation
                                                </h2>
                                                <div class="mt-8 flex justify-center">
                                                    <div class="inline-flex rounded-md shadow">
                                                        <button v-if="isSubscribed" @click="attendCommunitySession" class="inline-flex items-center justify-center px-5 py-3 border border-transparent text-base leading-6 font-medium rounded-md text-white bg-indigo-600 hover:bg-indigo-500 focus:outline-none focus:shadow-outline transition duration-150 ease-in-out">
                                                            Attend group session
                                                        </button>
                                                        <button v-else @click="subscribeAndAttend" class="inline-flex items-center justify-center px-5 py-3 border border-transparent text-base leading-6 font-medium rounded-md text-white bg-indigo-600 hover:bg-indigo-500 focus:outline-none focus:shadow-outline transition duration-150 ease-in-out">
                                                            Subscribe & Attend Group Session
                                                        </button>
                                                    </div>
                                                </div>
                                            </div>
                                            <div v-if="tooEarly" class="max-w-screen-xl mx-auto text-center py-12 px-4 sm:px-6 lg:py-16 lg:px-8">

                                                <h2 class="text-3xl leading-9 font-extrabold tracking-tight text-gray-900 sm:text-4xl sm:leading-10">
                                                    Session will start on
                                                    <span class="text-3xl leading-9 font-extrabold tracking-tight text-jiruto-zotSecondary  sm:text-4xl sm:leading-10">
                                                        {{ timeDateFromInput(session.groupSession.startsAt) }}
                                                    </span>
                                                </h2>

                                                <p>
                                                    you can start joining 5 minutes earlier
                                                </p>

                                                <div class="mt-8 flex justify-center">
                                                    <div class="inline-flex rounded-md shadow">
                                                        <button v-if="isSubscribed" @click="attendCommunitySession" class="inline-flex items-center justify-center px-5 py-3 border border-transparent text-base leading-6 font-medium rounded-md text-white bg-indigo-600 hover:bg-indigo-500 focus:outline-none focus:shadow-outline transition duration-150 ease-in-out">
                                                            Attend group session
                                                        </button>
                                                        <button v-else @click="subscribeAndAttend" class="inline-flex items-center justify-center px-5 py-3 border border-transparent text-base leading-6 font-medium rounded-md text-white bg-indigo-600 hover:bg-indigo-500 focus:outline-none focus:shadow-outline transition duration-150 ease-in-out">
                                                            Subscribe & Attend Group Session
                                                        </button>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                            <div v-if="session.status === 'attending' ">
                                            <div v-if="isGoodTimeToJoinSession"  class="max-w-screen-xl mx-auto text-center py-12 px-4 sm:px-6 lg:py-16 lg:px-8">
                                                <h2 class="text-3xl leading-9 font-extrabold tracking-tight text-gray-900 sm:text-4xl sm:leading-10">
                                                    Ready to dive in?
                                                </h2>
                                                <div class="mt-8 flex justify-center">
                                                    <div class="inline-flex rounded-md shadow">
                                                        <button @click="joinSession" class="inline-flex items-center justify-center px-5 py-3 border border-transparent text-base leading-6 font-medium rounded-md text-white bg-indigo-600 hover:bg-indigo-500 focus:outline-none focus:shadow-outline transition duration-150 ease-in-out">
                                                            Join the Session
                                                        </button>
                                                    </div>
                                                </div>
                                            </div>
                                            <div v-if="tooEarly" class="max-w-screen-xl mx-auto text-center py-12 px-4 sm:px-6 lg:py-16 lg:px-8">
                                                <h2 class="text-3xl leading-9 font-extrabold tracking-tight text-gray-900 sm:text-4xl sm:leading-10">
                                                    Session will start on
                                                    <span class="text-3xl leading-9 font-extrabold tracking-tight text-jiruto-zotSecondary  sm:text-4xl sm:leading-10">
                                                        {{ timeDateFromInput(session.groupSession.startsAt) }}
                                                    </span>
                                                </h2>
                                                <p>
                                                    you can start joining 5 minutes earlier
                                                </p>
                                            </div>
                                        </div>
                                    </div>
                                    <div v-if="tooLate" class="max-w-screen-xl mx-auto text-center py-12 px-4 sm:px-6 lg:py-16 lg:px-8">
                                        <h2 class="text-3xl leading-9 font-extrabold tracking-tight text-gray-900 sm:text-4xl sm:leading-10">
                                            Boy oh boy what a session
                                            <br>
                                            Too bad it ended
                                        </h2>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>


                <!-- Session Details -->
                <div v-if="session.status !== null" ref="sessionDetails" id="sessionDetails" class="bg-gray-50 h-full max-h-full rounded-r-lg lg:flex-shrink-0 lg:border-l lg:border-gray-200  overflow-y-auto scroller relative scrolling-touch">

                    <div v-if="sessionSubType ==='podium'" class="mt-1 mx-1 rounded-md bg-jiruto-zotSecondary text-white text-center py-px">
                        <p class="text-white text-base font-medium tracking-wider">Podium</p>
                    </div>
                    <div v-if="sessionSubType ==='singleRoom'" class="mt-1 mx-1 rounded-md bg-jiruto-zotDarker text-white text-center py-px">
                        <p class="text-white text-base font-medium tracking-wider">Group Session</p>
                    </div>
                    <div v-if="sessionSubType ==='multiRoom' || sessionSubType === 'multiRoomLobby'" class="mt-1 mx-1 rounded-md bg-jiruto-flatRed text-white text-center py-px">
                        <p class="text-white text-base font-medium tracking-wider">Multi-Room Session</p>
                    </div>
                    <div v-if="session.groupSession.sessionType ==='private'" class="mt-1 mx-1 rounded-md bg-indigo-500 text-white text-center py-px">
                        <p class="text-white text-base font-medium tracking-wider">Private Session</p>
                    </div>

                    <div class="pl-6 flex-grow lg:w-80 pr-4 py-4 sm:pr-6 lg:pr-8 xl:pr-4">

                        <edit-group-session-details-form
                                v-if="isEditingDetails" :topic="session.groupSession.topic" :agenda="session.groupSession.agenda"
                                :startsAt="new Date(this.session.groupSession.startsAt)" :sessionID="sessionID"
                                @newGroupSessionDetailsSaved="newGroupSessionDetailsSaved"
                                @cancelDetailsEdit="cancelDetailsEdit"></edit-group-session-details-form>

                        <div v-else class="block w-full">
                            <div class="flex items-start justify-between">
                                <div class="w-3/4 break-normal">
                                    <p class="text-xl leading-7 font-semibold text-gray-900 break-normal">
                                        {{ session.groupSession.topic }}
                                    </p>
                                </div>
                                <div class="mt-1 ml-2 flex justify-end items-center w-1/4">
                                    <div class="flex items-center">
                                        <span v-if="session.status === 'attending'" class="flex-shrink-0 inline-block px-2 py-0.5 text-teal-800 text-xs leading-4 font-medium bg-teal-100 rounded-full">Attending</span>
                                        <span v-if="session.status === 'invited'" class="flex-shrink-0 inline-block px-2 py-0.5  text-white text-xs leading-4 font-medium bg-jiruto-flatRed rounded-full">Invited</span>
                                    </div>
                                </div>
                                <div v-if="session.status === 'attending' && !tooLate" class="mt-1 inline-block text-left ml-1" style="z-index: 100">
                                    <div class="">
                                        <button @click.stop="showOptionsMenu = !showOptionsMenu" class="flex items-center text-gray-400 hover:text-gray-600 focus:outline-none active:text-gray-900" aria-label="Options" id="options-menu" aria-haspopup="true" aria-expanded="true">
                                            <svg class="h-5 w-5" fill="currentColor" viewBox="0 0 20 20">
                                                <path d="M10 6a2 2 0 110-4 2 2 0 010 4zM10 12a2 2 0 110-4 2 2 0 010 4zM10 18a2 2 0 110-4 2 2 0 010 4z" />
                                            </svg>
                                        </button>
                                    </div>

                                    <transition enter-class="transform opacity-0 scale-y-95"
                                                enter-active-class="transition ease-out duration-100"
                                                enter-to-class="transform opacity-100 scale-y-100"
                                                leave-class="transform opacity-100 scale-y-100"
                                                leave-active-class="transition ease-in duration-75"
                                                leave-to-class="transform opacity-0 scale-y-95">

                                        <div v-if="showOptionsMenu" v-on-clickaway="closeOptionsMenu" class="origin-top-right right-5 absolute mt-2 w-56 rounded-md shadow-lg">
                                            <div class="rounded-md bg-gray-100 shadow-xs">
                                                <div class="py-1" role="menu" aria-orientation="vertical" aria-labelledby="options-menu">

                                                    <button v-if="!canEdit" @click="cancelAttendance"  class="group flex w-full items-center px-4 py-2 text-sm leading-5 text-gray-700 hover:bg-gray-200 hover:text-gray-900 focus:outline-none focus:bg-gray-100 focus:text-gray-900" role="menuitem">
                                                        <svg class="mr-3 h-5 w-5 text-gray-400 group-hover:text-gray-500 group-focus:text-gray-500" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
                                                            <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M15 12H9m12 0a9 9 0 11-18 0 9 9 0 0118 0z"></path>
                                                        </svg>
                                                        Cancel attendance
                                                    </button>

                                                    <button v-if="canEdit" @click="editSessionPressed"  class="group flex w-full items-center px-4 py-2 text-sm leading-5 text-gray-700 hover:bg-gray-200 hover:text-gray-900 focus:outline-none focus:bg-gray-100 focus:text-gray-900" role="menuitem">
                                                        <svg class="mr-3 h-5 w-5 text-gray-400 group-hover:text-gray-500 group-focus:text-gray-500" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
                                                            <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M15.232 5.232l3.536 3.536m-2.036-5.036a2.5 2.5 0 113.536 3.536L6.5 21.036H3v-3.572L16.732 3.732z"></path>
                                                        </svg>
                                                        Edit session
                                                    </button>

                                                    <button v-if="canEdit" @click="deleteSessionPressed"  class="group flex w-full items-center px-4 py-2 text-sm leading-5 text-gray-700 hover:bg-gray-200 hover:text-gray-900 focus:outline-none focus:bg-gray-100 focus:text-gray-900" role="menuitem">
                                                        <!-- Heroicon name: pencil-alt -->
                                                        <svg class="mr-3 h-5 w-5 text-gray-400 group-hover:text-gray-500 group-focus:text-gray-500" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">-->
                                                            <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M19 7l-.867 12.142A2 2 0 0116.138 21H7.862a2 2 0 01-1.995-1.858L5 7m5 4v6m4-6v6m1-10V4a1 1 0 00-1-1h-4a1 1 0 00-1 1v3M4 7h16"></path>
                                                        </svg>
                                                        Delete Session
                                                    </button>
                                                </div>
                                            </div>
                                        </div>
                                    </transition>
                                </div>
                            </div>

                            <div class="flex">
                                <div class="flex items-center text-xs leading-5 text-gray-500">
                                    <svg class="flex-shrink-0 mr-1.5 h-4 w-4 text-gray-400" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor">
                                        <path fill-rule="evenodd" d="M6 2a1 1 0 00-1 1v1H4a2 2 0 00-2 2v10a2 2 0 002 2h12a2 2 0 002-2V6a2 2 0 00-2-2h-1V3a1 1 0 10-2 0v1H7V3a1 1 0 00-1-1zm0 5a1 1 0 000 2h8a1 1 0 100-2H6z" clip-rule="evenodd" />
                                    </svg>
                                    <span>
                                        <time datetime="2020-01-07">{{timeDateFromInput(session.groupSession.startsAt)}}</time>
                                    </span>
                                </div>
                            </div>

                            <div class="">
                                <p class="mt-1 text-xs leading-2 text-gray-600" style="white-space: pre-line">
                                    {{ session.groupSession.agenda }}
                                </p>
                            </div>

                            <div v-if="session.status === 'invited' && !tooLate" class="mt-3">
                                <div class="">
                                    <div class="-mt-px flex">
                                        <div class="w-0 flex-1 flex border-r border-gray-200">
                                            <button class="bg-indigo-500 relative -mr-px w-0 flex-1 inline-flex items-center justify-center py-2 text-sm leading-5 text-gray-700 font-medium border border-transparent rounded-l-lg hover:text-gray-500 hover:bg-indigo-600 active:bg-indigo-700 active:outline-none focus:outline-none focus:shadow-outline-blue focus:border-blue-300 focus:z-10 transition ease-in-out duration-150">
                                                <span class="text-white">Accept</span>
                                            </button>
                                        </div>
                                        <div class="-ml-px w-0 flex-1 flex">
                                            <button @click="declineInvitation" class="relative w-0 flex-1 inline-flex items-center justify-center py-2 text-sm leading-5 text-gray-700 font-medium border  rounded-r-lg hover:text-gray-900  hover:bg-gray-100 active:bg-gray-200 focus:outline-none focus:shadow-outline-blue focus:border-blue-300 focus:z-10 transition ease-in-out duration-150">
                                                <span class="">Decline</span>
                                            </button>
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div v-if="this.session.status !== 'attending' && session.groupSession.sessionType === 'community' && !tooLate" class="mt-3">
                                <div class="">
                                    <div class="-mt-px flex">
                                        <div class="-ml-px w-0 flex-1 flex">
                                            <button v-if="isSubscribed" @click="attendCommunitySession" class="relative w-0 flex-1 inline-flex items-center justify-center py-2 text-sm leading-5 text-gray-700 font-medium border  rounded-lg hover:text-gray-900  hover:bg-gray-100 active:bg-gray-200 focus:outline-none focus:shadow-outline-blue focus:border-blue-300 focus:z-10 transition ease-in-out duration-150">
                                                <span  class="">Attend Group Session</span>
                                            </button>
                                            <button v-else @click="subscribeAndAttend" class="relative w-0 flex-1 inline-flex items-center justify-center py-2 text-sm leading-5 text-gray-700 font-medium border  rounded-lg hover:text-gray-900  hover:bg-gray-100 active:bg-gray-200 focus:outline-none focus:shadow-outline-blue focus:border-blue-300 focus:z-10 transition ease-in-out duration-150">
                                                <span class="">Subscribe & Attend Group Session</span>
                                            </button>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <!-- Presenters -->
                        <group-session-page-presenters-section
                                :canEdit="canEdit" :session="session" :presenters-prop="presenters" :sessionID="sessionID"
                                :communityOwner="communityOwner" :communityName="communityName" @removeParticipantLocally="removeParticipantLocally"
                                @addPresenterLocally="addPresenterLocally" @removePresenterLocally="removePresenterLocally"
                                @addParticipantLocally="addParticipantLocally" @removeParticipant="removeParticipant"/>

                        <!-- Participants -->
                        <group-session-page-participants-section
                                :can-edit="canEdit" :session="session" :sessionID="sessionID" :participants-prop="noPresenters"
                                :communityOwner="communityOwner" :communityName="communityName" :invitees-and-attendees="inviteesAndAttendees"
                                @addParticipantLocally="addParticipantLocally" @removeParticipant="removeParticipant"
                                @addPresenterLocally="addPresenterLocally" @removePresenterLocally="removePresenterLocally"
                                @removeParticipantLocally="removeParticipantLocally"></group-session-page-participants-section>

                    </div>
                </div>
            </div>
        </div>

        <alertNotification class="z-100" style="z-index: 50" :open="showJoinSessionAlertNotification" notificationType="alert">
            <template v-slot:header>
                Can't join session right now.
            </template>
            <template v-slot:text>
                {{ wrongTimeMessage }}
            </template>
        </alertNotification>

        <alertNotification class="z-100" style="z-index: 50" :open="showAddParticipantAlertNotification" notificationType="alert">
            <template v-slot:header>
                Adding participant failed.
            </template>
            <template v-slot:text>
                Please try again.
            </template>
        </alertNotification>

        <alert-modal :open="showDeleteSessionAlertModal" v-on:close="closeDeleteSessionAlertModal" v-on:reset="deleteSession">
            <template v-slot:header>
                Session deletion requested!
            </template>
            <template v-slot:text>
                You've requested to delete this session. Please confirm if you meant to take this action.
            </template>
            <template v-slot:button>
                Delete
            </template>
        </alert-modal>

    </div>
</template>

<script>
    import {mapActions} from 'vuex'
    import AlertModal from '@/components/AlertModal'
    import {directive as onClickaway} from 'vue-clickaway'
    import AlertNotification from '@/components/ActionNotification'
    import DailyIframe from '@daily-co/daily-js';
    import {dateFromInput, timeDateFromInput} from '@/utils/helpers'
    import * as _ from 'lodash'
    import * as axios from '@/axios-auth'
    import ProfileImageWithPopCard from "@/components/ProfileImageWithPopCard";
    import EditGroupSessionDetailsForm from "@/components/EditGroupSessionDetailsForm";
    import GroupSessionPagePresentersSection from "@/components/GroupSessionPagePresentersSection";
    import GroupSessionPageParticipantsSection from "@/components/GroupSessionPageParticipantsSection";
    export default {
        name: "GroupSessionPage",
        components: {
            GroupSessionPageParticipantsSection,
            GroupSessionPagePresentersSection, EditGroupSessionDetailsForm,
            AlertModal, ProfileImageWithPopCard, AlertNotification},
        props: ['sessionID', 'anonymousUser'],
        directives: {
            onClickaway
        },
        data(){
            return {
                showSuccessLinkCopy: false,
                showDeleteSessionAlertModal: false,
                showAddParticipantAlertNotification: false,
                showOptionsMenu: false,
                wrongTimeMessage: "Can't join the session right now",
                showJoinSessionAlertNotification: false,
                isEditingDetails: false,
                isInCall: false,
                callFrame: null,
                session: {
                    status: null,
                    role: null,
                    groupSession: null,
                },
            }
        },

        created(){
            this.fetchSession()
        },
        watch:{
            sessionID(){
                this.fetchSession()
            }
        },
        mounted() {
            window.scrollTo(0,0)
        },
        computed: {
            presenters(){
                if(this.inviteesAndAttendees !== null){
                    return _.filter(this.inviteesAndAttendees, (participant) => {
                        return participant.role === 'presenter'
                    })
                }
                return []
            },
            noPresenters(){
                if(this.inviteesAndAttendees !== null){
                    return _.filter(this.inviteesAndAttendees, (participant) => {
                        return participant.role !== 'presenter'
                    })
                }
                return []
            },
            isSubscribed: {
                get: function() { return this.$store.state.community.isSubscribed },
                set: function(newValue) { this.$store.commit("setIsSubscribed", newValue)}
            },
            communityOwner() {
                if(this.session.groupSession.community !== null){
                    return this.session.groupSession.community.owner.username
                } else {
                    return ""
                }
            },
            communityName() {
                if(this.session.groupSession.community !== null){
                    return this.session.groupSession.community.name
                } else {
                    return ""
                }
            },
            communityNameForUrl(){
                return this.communityName.replace(/\s/g, "-")
            },
            sessionSubType(){
                if(this.session.groupSession.sessionSubType !== null ){
                    return this.session.groupSession.sessionSubType
                } else
                    return "empty"
            },
            canEdit(){
                return this.session.groupSession.initiator.JID === this.$store.state.jirutoUser.JID &&
                        !this.tooLate
            },
            tooEarly(){
                const date = new Date(this.session.groupSession.startsAt)
                const now = new Date()
                return now.getTime() - date.getTime() < -300000
            },
            tooLate(){
                const date = new Date(this.session.groupSession.startsAt)
                const now = new Date()
                return now.getTime() - date.getTime() >= 7200000
            },
            isGoodTimeToJoinSession(){
                const date = new Date(this.session.groupSession.startsAt)
                const now = new Date()
                const moreThanFiveMinutesBefore = now.getTime() - date.getTime() >= -300000
                const lessThanTwoHoursAfter = now.getTime() - date.getTime() < 7200000
                return lessThanTwoHoursAfter && moreThanFiveMinutesBefore
            },
            inviteesAndAttendees(){
                const invitees = this.session.groupSession.invitees
                const participants = this.session.groupSession.attendees

                const inviteesWithoutMe = _.filter(invitees, (user) => {
                    return (user.user.JID !== this.$store.state.jirutoUser.JID)
                })

                const participantsWithoutMe = _.filter(participants, (user) => {
                    return (user.user.JID !== this.$store.state.jirutoUser.JID)
                })

                let both = inviteesWithoutMe.concat(participantsWithoutMe)
                return _.orderBy(both, ['user.firstName'], ['asc'])
            },
        },
        methods: {
            getParticipant(userID){
                const index = _.findIndex(this.inviteesAndAttendees, (participant) => {
                    return participant.user.JID === userID
                });
                return index !== -1 ? this.inviteesAndAttendees[index] : null
            },
            // isParticipant(userID){
            //     const index = _.findIndex(this.inviteesAndAttendees(), (participant) => {
            //         return participant.user.JID === userID
            //     });
            //     return index !== -1
            // },
            // isAttending(userID){
            //     const index = _.findIndex(this.inviteesAndAttendees(), (participant) => {
            //         return participant.user.JID === userID
            //     });
            //     return index !== -1
            // },
            makePresenterLocally(){
            },
            addPresenterLocally(presenter){
                const participant = this.getParticipant(presenter.user.JID)
                if (participant !== null) {
                    presenter.status = participant.status
                    this.removeParticipantLocally(presenter)
                } else {
                    presenter.status = "invited"
                }

                presenter.role = "presenter"
                this.addParticipantLocally(presenter)
            },
            removePresenterLocally(presenter){
                this.removeParticipantLocally(presenter)
                presenter.role = "participant"
                this.addParticipantLocally(presenter)
            },
            removeParticipant(participant){
                let attendeeOrInvitee
                if (participant.status === 'invited') {
                    attendeeOrInvitee = "invitee"
                } else if (participant.status === 'attending') {
                    attendeeOrInvitee = "attendee"
                } else {
                    return
                }
                this.removeParticipantLocally(participant)
                axios.jigo.put(`/v2/groupSession/${this.sessionID}/${attendeeOrInvitee}/${participant.user.username}/remove`)
                .then((resp) => {
                    if(resp.status !== 200) {
                        this.addParticipantLocally(participant)
                        console.error("ERROR: unwanted status returned", resp.status)
                    }
                })
                .catch((err) => {
                    this.addParticipantLocally(participant)
                    console.error("ERROR: removeParticipant FAILED", err)
                })
            },
            ...mapActions(['subscribe']),
            subscribeAndAttend(){
                this.subscribe()
                .then(() => {
                    this.attendCommunitySession()
                })
                .catch((err) => {
                    console.error("ERROR: subscribeAndAttend FAILED ", err)
                })
            },
            closeDeleteSessionAlertModal(){
                this.showDeleteSessionAlertModal = false
            },
            isAParticipant(inviteeID){
                const index =  _.findIndex(this.inviteesAndAttendees, (participant) => {
                    return participant.user.JID === inviteeID
                });
                return index !== -1
            },

            cancelAttendance(){
                let loader = this.$loading.show({
                    onCancel: this.loaderCanceled,
                });
                axios.jigo.put(`/v2/groupSession/${this.sessionID}/cancelAttendance`, this.newDetails,{validateStatus: (status) => {
                        return status < 500
                    }})
                .then((resp) => {
                    if(resp.status === 200) {
                        loader.hide()
                        console.log("History:", this.$router.history)
                        if(this.$router.history.length > 0){
                            this.$router.back()
                            return
                        }
                        if(this.session.groupSession.sessionType === "private"){
                            this.$router.push("/my/groupSessions")
                            return
                        }
                        if(this.session.groupSession.sessionType === "community"){
                            this.$router.push(`/${this.communityOwner}/${this.communityName}/groupSessions`)
                        }

                    } else {
                        loader.hide()
                        console.error("ERROR: unwanted status returned", resp.status)
                    }
                })
                .catch((err) => {
                    loader.hide()
                    console.error("ERROR: cancelAttendance FAILED", err)
                })
            },
            saveDetailsLocally(newDetails){
                this.session.groupSession.topic = newDetails.topic
                this.session.groupSession.agenda = newDetails.agenda
                this.session.groupSession.startsAt = newDetails.startsAt
            },
            cancelDetailsEdit(){
                this.isEditingDetails = false
            },
            newGroupSessionDetailsSaved(newDetails){
                this.isEditingDetails = false
                this.saveDetailsLocally(newDetails)
            },
            deleteSession(){
                let loader = this.$loading.show({
                    onCancel: this.loaderCanceled,
                });
                axios.jigo.delete(`/v2/groupSession/${this.sessionID}/delete`,)
                .then((resp) => {
                    if(resp.status === 200) {
                        loader.hide()
                        this.$router.push("/my/groupSessions")
                    } else {
                        loader.hide()
                        console.error("ERROR: unwanted status returned", resp.status)
                    }
                })
                .catch((err) => {
                    loader.hide()
                    console.error("ERROR: deleteSession FAILED", err)
                })
            },
            deleteSessionPressed(){
                this.showOptionsMenu = false
                this.showDeleteSessionAlertModal = true
            },
            editSessionPressed(){
                if(!this.canEdit) {
                    return
                }
                this.showOptionsMenu = false
                this.isEditingDetails = true
            },
            closeOptionsMenu(){
                this.showOptionsMenu = false
            },
            removeParticipantLocally(participant){
                if (participant.status === "invited"){
                    const index =  _.findIndex(this.session.groupSession.invitees, (invitee) => {
                        return invitee.user.JID === participant.user.JID
                    });
                    this.session.groupSession.invitees.splice(index, 1)
                }

                if (participant.status === "attending"){
                    const index =  _.findIndex(this.session.groupSession.attendees, (attendee) => {
                        return attendee.user.JID === participant.user.JID
                    });
                    this.session.groupSession.attendees.splice(index, 1)
                }
            },
            addParticipantLocally(participant){
                if (participant.status === "invited"){
                    this.session.groupSession.invitees.splice(this.session.groupSession.invitees.length, 0, participant)
                }
                if (participant.status === "attending"){
                    this.session.groupSession.attendees.splice(this.session.groupSession.attendees.length, 0, participant)
                }
            },
            leaveSession(){
                this.callFrame.leave().then(() => {
                    this.destroySession()
                }).catch((err) => {
                    console.error("ERROR: callFrame.leave FAILED", err)
                })
            },
            timeDateFromInput,
            dateFromInput,
            joinSession(){
                let loader = this.$loading.show({
                    onCancel: this.loaderCanceled,
                });
                let anonymousStringify  = JSON.stringify(this.anonymousUser)
                let base64Token = btoa(anonymousStringify)
                axios.jigo.put(`/v2/groupSession/${this.sessionID}/join`, null ,{headers: {anonymous: base64Token}})
                .then((resp) => {
                    if(resp.status === 200) {
                        const tokenObject = resp.data
                        this.connectSession(tokenObject.token)
                        loader.hide()

                    } else {
                        loader.hide()
                        console.error("ERROR: unwanted status returned", resp.status)

                    }
                })
                .catch((err) => {
                    loader.hide()
                    console.error("ERROR: joinSession FAILED", err)
                    const message = err.response.data.trim()
                    if(message === "JoinSessionTooLate"){
                        this.wrongTimeMessage = "It's too late to join this session"
                    } else if(message === "JoinSessionTooEarly"){
                        this.wrongTimeMessage = "It's too early to join this session"
                    }
                    this.showJoinSessionAlertNotification = true
                    setTimeout(() => this.showJoinSessionAlertNotification = false, 5000)
                })
            },
            connectSession(token){
                let parentElement = this.$refs.dailyFrame
                this.callFrame = DailyIframe.createFrame(parentElement, {
                    showLeaveButton: false,
                    showFullscreenButton: true,
                    iframeStyle: {
                        width: '100%',
                        height: '100%'
                    }
                });
                this.callFrame.on
                this.isInCall = true
                this.callFrame.join({url: `https://jiruto.daily.co/${this.sessionID}?t=${token}`})
                .then(() => {
                    this.updateUserDidAttend()
                })
                .catch((err) => {
                    console.error("ERROR: callFrame.join FAILED", err)
                })
            },
            updateUserDidAttend(){
                axios.jigo.put(`/v2/groupSession/${this.sessionID}/updateUserDidAttend`, {validateStatus: (status) => {
                        return status < 500
                    }})
                .then(() => {
                    console.log("Updated user did attend successfully!")
                })
                .catch((err) => {
                    console.error("ERROR: updateUserDidAttend FAILED", err)
                })
            },
            attendSession(){
                let loader = this.$loading.show({
                    onCancel: this.loaderCanceled,
                });
                axios.jigo.put(`/v2/groupSession/${this.sessionID}/attend`)
                .then((resp) => {
                    if(resp.status === 200) {
                        console.log("attending:", resp.data)
                        this.session.status = "attending"
                        loader.hide()
                    } else {
                        loader.hide()
                        console.error("ERROR: unwanted status returned", resp.status)
                    }
                })
                .catch((err) => {
                    loader.hide()
                    console.error("ERROR: attendSession FAILED", err)
                })
            },
            attendCommunitySession(){
                let loader = this.$loading.show({
                    onCancel: this.loaderCanceled,
                });
                axios.jigo.put(`/v2/community/${this.communityOwner}/${this.communityNameforUrl}/groupSession/${this.sessionID}/attend`)
                .then((resp) => {
                    if(resp.status === 200) {
                        console.log("attending community session:", resp.data)
                        this.session.status = "attending"
                        loader.hide()
                    } else {
                        console.error("ERROR: unwanted status returned", resp.status)
                        loader.hide()
                    }
                })
                .catch((err) => {
                    loader.hide()
                    console.error("ERROR: attendSession FAILED", err)
                })
            },
            declineInvitation(){
                axios.jigo.put(`/v2/groupSession/${this.sessionID}/decline`)
                .then((resp) => {
                    if(resp.status === 200) {
                       this.$router.push("/my/groupSessions")
                    } else {
                        console.error("ERROR: unwanted status returned", resp.status)
                    }
                })
                .catch((err) => {
                    console.error("ERROR: declineSession FAILED", err)
                })
            },
            fetchSession(){
                let loader = this.$loading.show({
                    onCancel: this.loaderCanceled,
                });
                let anonymousStringify  = JSON.stringify(this.anonymousUser)
                let base64Token = btoa(anonymousStringify)

                axios.jigo.get(`/v2/groupSession/${this.sessionID}`, {headers: {anonymous: base64Token}})
                .then((resp) => {
                    if(resp.status === 200) {
                        loader.hide()
                        this.session = resp.data
                        console.log("This Session", this.session)
                        if(this.session.groupSession.sessionType === "community"){
                            this.$store.dispatch("setCommunityOwner", this.communityOwner)
                            this.$store.dispatch("setCommunityName", this.communityName)
                        }
                        if(this.session.groupSession.attendees === null) {
                            this.session.groupSession.attendees = []
                        }
                        if(this.session.groupSession.invitees === null) {
                            this.session.groupSession.invitees = []
                        }
                    } else {
                        loader.hide()
                        console.error("ERROR: unwanted status returned", resp.status)
                    }
                })
                .catch((err) => {
                    loader.hide()
                    console.error("ERROR: fetchSession FAILED", err)
                })
            },
            destroySession(){
                this.callFrame.destroy()
                .then(() => {
                    this.isInCall = false
                })
                .catch((err) => {
                    console.error("ERROR: destroying daily CallFrame FAILED", err)
                })
            }
        },
        beforeDestroy() {
            if (this.callFrame !== null) {
                this.destroySession()
            }
        }
    }
</script>

<style scoped>

</style>
