<template>
    <section>
        <div>
            <div v-if="presenters.length !== 0" class="pt-6 pb-2 mt-5">
                <h2 class="text-sm leading-5 font-semibold">Presenters</h2>
            </div>
            <div>
                <div class="divide-y divide-gray-200">
                    <transition-group name="presenters" tag="ul" class="divide-y divide-gray-200"
                                      enter-class="transform opacity-0 scale-y-95"
                                      enter-active-class="transition ease-out duration-700"
                                      enter-to-class="transform opacity-100 scale-y-100"
                                      leave-class="transform opacity-100 scale-y-100"
                                      leave-active-class="transition ease-in duration-700"
                                      leave-to-class="transform opacity-0 scale-y-95"
                    >
                        <li v-for="(presenter) in presenters " :key="presenter.user.JID" class="py-4 px-1">
                            <SessionParticipantItem :participant-prop="presenter" :canEdit="canEdit"
                                                    @revokePresentation="removePresenter" @makePresenter="addPresenter"
                                                    @removeParticipant="removeParticipant"
                                                    @removePresenter="removePresenter" :sessionID="sessionID" item-type="presenter"
                            ></SessionParticipantItem >
                        </li>
                    </transition-group>
                </div>
                <!--                            <div class="py-4 text-sm leading-5 border-t border-gray-200">-->
                <!--                                <a href="#" class="text-indigo-600 font-semibold hover:text-indigo-900">View all activity &rarr;</a>-->
                <!--                            </div>-->
            </div>
        </div>


        <div v-if="canEdit && session.groupSession.sessionSubType === 'podium'" class="sm:mt-2 sm:gap-4 sm:items-start sm:border-gray-200 sm:py-2">
            <div class="flex flex-col">
                <label for="searchSubscribers" class="block text-sm font-medium leading-5 text-gray-700 sm:mt-px sm:pt-2">
                    Invite Presenters
                </label>
            </div>
            <div class="px-2 mt-2">
                <div class="mt-5 sm:mt-1">
                    <div class="">
                        <label for="searchSubscribers" class="sr-only">Search community subscribers</label>
                        <div class="relative rounded-md shadow-sm sm:max-w-xs">
                            <div class="absolute inset-y-0 left-0 pl-3 flex items-center pointer-events-none">
                                <!-- Heroicon name: search -->
                                <svg class="mr-3 h-4 w-4 text-gray-400" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor">
                                    <path fill-rule="evenodd" d="M8 4a4 4 0 100 8 4 4 0 000-8zM2 8a6 6 0 1110.89 3.476l4.817 4.817a1 1 0 01-1.414 1.414l-4.816-4.816A6 6 0 012 8z" clip-rule="evenodd" />
                                </svg>
                            </div>
                            <input type="text" autocomplete="off" v-model="subscribersSearchTerm" id="searchSubscribers" @focus="searchSubscribers" @input="searchSubscribers"
                                   class="h-8 border pl-9 rounded-lg focus:outline-none block border-gray-300 rounded-md focus:ring-indigo-500 focus:border-indigo-500 border w-full rounded-md transition duration-150 ease-in-out sm:text-sm sm:leading-5 " placeholder="Search community subscribers">
                        </div>
                        <div v-if="showPresentersSelection && subscribersList.length > 0" class="mb-10">
                            <userSelection :users="subscribersList" @close="showPresentersSelection = false"
                                           @userSelected="addPresenter" :open="showPresentersSelection" class="z-50 w-full "/>
                        </div>
                    </div>
                </div>
                <button v-if="canEdit" @click="toggleShowOutsidePresenterForm"
                        class="group mt-1 flex w-full items-center py-2 text-sm leading-5 text-gray-500 hover:text-gray-700 active:text-gray-900 focus:outline-none"
                        role="menuitem">
                    <!-- Heroicon name: pencil-alt -->
                    <svg class="mr-3 h-5 w-5 text-gray-400 group-hover:text-gray-500 group-active:text-gray-900" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">-->
                        <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M12 14l9-5-9-5-9 5 9 5zm0 0l6.16-3.422a12.083 12.083 0 01.665 6.479A11.952 11.952 0 0012 20.055a11.952 11.952 0 00-6.824-2.998 12.078 12.078 0 01.665-6.479L12 14zm-4 6v-7.5l4-2.222"></path>
                    </svg>
                    Outside presenter
                </button>
            </div>
        </div>

        <div v-if="showAddOutsidePresenterForm" class="border p-3 rounded-md">
            <div class="">
                <h2 class="text-sm leading-5 font-semibold">Add an outside presenter</h2>
            </div>
            <div class="block w-full">
                <div class="px-1">
                    <div class="">
                        <div class="grid grid-cols-2 gap-4">
                            <div class=" sm:gap-4 sm:items-start sm:border-gray-200 sm:pt-5">
                                <!--                                                    <label for="firstName" class="block text-sm font-medium leading-5 text-gray-700">First Name</label>-->
                                <div class="mt-1 sm:mt-0">
                                    <div class="max-w-lg rounded-md shadow-sm sm:max-w-xs">
                                        <label for="firstName"></label>
                                        <input type="text" v-model="newOutsidePresenter.firstName" id="firstName"
                                               @focusin="$v.newOutsidePresenter.firstName.$reset"
                                               @focusout="$v.newOutsidePresenter.firstName.$touch"
                                               placeholder="First Name"
                                               class=" block border-gray-300 rounded-md focus:ring-indigo-500 focus:border-indigo-500 border w-full rounded-md transition duration-150 ease-in-out sm:text-sm sm:leading-5"
                                               :class="{'border-red-500 placeholder-red-500': $v.newOutsidePresenter.firstName.$error}">
                                    </div>
                                </div>
                            </div>

                            <div class=" sm:gap-4 sm:items-start sm:border-gray-200 sm:pt-5">
                                <div class="mt-1 sm:mt-0">
                                    <div class="max-w-lg rounded-md shadow-sm sm:max-w-xs">
                                        <label for="lastName"></label>
                                        <input type="text" v-model="newOutsidePresenter.lastName" id="lastName"
                                               @focusin="$v.newOutsidePresenter.lastName.$reset"
                                               @focusout="$v.newOutsidePresenter.lastName.$touch"
                                               placeholder="Last Name"
                                               class="block border-gray-300 rounded-md focus:ring-indigo-500 focus:border-indigo-500 border w-full rounded-md transition duration-150 ease-in-out sm:text-sm sm:leading-5"
                                               :class="{'border-red-500 placeholder-red-500': $v.newOutsidePresenter.lastName.$error}">
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="mt-8 border-t border-gray-200 pt-5">
                    <div class="flex justify-end">
                        <span class="inline-flex rounded-md shadow-sm">
                            <button @click="cancelAddOutsidePresenter" type="button" class="py-2 px-4 border border-gray-300 rounded-md text-sm leading-5 font-medium text-gray-700 hover:text-gray-500 focus:outline-none focus:border-blue-300 focus:shadow-outline-blue active:bg-gray-50 active:text-gray-800 transition duration-150 ease-in-out">
                                Cancel
                            </button>
                        </span>
                        <span class="ml-3 inline-flex rounded-md shadow-sm">
                            <button @click="addOutsidePresenter" type="submit" class="inline-flex justify-center py-2 px-4 border border-transparent text-sm leading-5 font-medium rounded-md text-white bg-indigo-600 hover:bg-indigo-500 focus:outline-none focus:border-indigo-700 focus:shadow-outline-indigo active:bg-indigo-700 transition duration-150 ease-in-out">
                                Add & Create Link
                            </button>
                        </span>
                    </div>
                </div>
            </div>
        </div>
    </section>
</template>

<script>
    import * as _ from 'lodash'
    import { required } from 'vuelidate/lib/validators'
    import * as axios from '@/axios-auth'
    import SessionParticipantItem from "@/components/SessionParticipantItem";
    import UserSelection from "@/components/UserSelection";
    export default {
        name: "GroupSessionPagePresentersSection",
        components: {UserSelection, SessionParticipantItem},
        props: ['sessionID', 'session', 'presentersProp', 'canEdit', 'communityOwner', 'communityName'],
        data(){
            return {
                presenters: [],
                showAddOutsidePresenterForm: false,
                newOutsidePresenter: {
                    firstName: "",
                    lastName: "",
                    JID: "",
                    magicToken: "",
                    isAnonymous: true,
                },
                subscribersList: [],
                subscribersSearchTerm: "",
                showPresentersSelection: false,

            }
        },
        validations: {
            newOutsidePresenter: {
                firstName: {
                    required
                },
                lastName: {
                    required
                },
            },
        },
        created(){
            this.presenters = _.cloneDeep(this.presentersProp)
        },
        watch: {
            presentersProp(){
                this.presenters = _.cloneDeep(this.presentersProp)
            }
        },
        computed: {
            communityNameForUrl() {
                return this.communityName.replace(/\s/g, "-")
            }
        },
        methods: {
            removeParticipant(participant){
                this.$emit("removeParticipant", participant)
            },
            toggleShowOutsidePresenterForm(){
                if(this.showAddOutsidePresenterForm){
                    this.cancelAddOutsidePresenter()
                }else {
                    this.showAddOutsidePresenterForm = true
                    this.$v.newOutsidePresenter.$reset()
                }
            },
            cancelAddOutsidePresenter(){
                this.$v.newOutsidePresenter.$reset()
                this.showAddOutsidePresenterForm = false
            },
            addOutsidePresenter(){
                let presenter = _.cloneDeep(this.newOutsidePresenter)
                axios.jigo.post(`/v2/community/${this.communityOwner}/${this.communityNameForUrl}/groupSession/${this.session.groupSession.JID}/presenters/addOutsidePresenter`, presenter)
                .then((resp) => {
                    if(resp.status === 200) {
                        this.showAddOutsidePresenterForm = false
                        presenter = resp.data.user
                        presenter.user.magicToken = resp.data.magicToken
                        presenter.showMagicLink = true

                        // this.addParticipantLocally(presenter)
                        this.$emit("addParticipantLocally", presenter)
                        this.newOutsidePresenter = {
                            firstName: "",
                            lastName: "",
                        }

                        setTimeout(() => {
                            let element  = document.querySelector(`#sessionDetails`)
                            const newPosition = element.scrollHeight.valueOf() - element.clientHeight
                            element.scrollTo({
                                top: newPosition,
                                behavior: "smooth"
                            })
                        }, 50)
                    } else {
                        console.error("ERROR: unwanted status returned", resp.status)
                    }
                })
                .catch((err) => {
                    console.error("ERROR: searchCoLearners FAILED", err)
                })
            },
            addPresenter(user){
                const presenter = {
                    user: user,
                    role: "presenter"
                }
                this.$emit("addPresenterLocally", presenter)

                axios.jigo.post(`/v2/community/${this.communityOwner}/${this.communityNameForUrl}/groupSession/${this.sessionID}/presenters/add/${user.JID}`)
                .then((resp) => {
                    if(resp.status !== 200) {
                        this.$emit("removeParticipantLocally", presenter)
                        console.error("ERROR: unwanted status returned", resp.status)
                    }
                })
                .catch((err) => {
                    this.$emit("removeParticipantLocally", presenter)
                    console.error("ERROR: addPresenter FAILED", err)
                })
            },
            removePresenter(presenter){
                if(presenter.user.isAnonymous) {
                    this.$emit("removeParticipantLocally", presenter)
                } else {
                    this.$emit("removePresenterLocally", presenter)
                }
                axios.jigo.delete(`/v2/community/${this.communityOwner}/${this.communityNameForUrl}/groupSession/${this.sessionID}/presenters/remove/${presenter.user.JID}`)
                .then((resp) => {
                    if(resp.status !== 200) {
                        this.$emit("addParticipantLocally", presenter)
                        console.error("ERROR: unwanted status returned", resp.status)
                    }
                })
                .catch((err) => {
                    this.$emit("addParticipantLocally", presenter)
                    console.error("ERROR: removePresenter FAILED", err)
                })
            },
            searchSubscribers: _.debounce(function ()  {
                if(this.subscribersSearchTerm.trim() === "") {
                    return
                }
                axios.jigo.get(`/v2/community/searchSubscribers/${this.communityOwner}/${this.communityNameForUrl}`, {params: {q: this.subscribersSearchTerm.trim()}})
                .then((resp) => {
                    if(resp.status === 200) {
                        this.subscribersList = resp.data
                        this.showPresentersSelection = true
                        setTimeout(() => {
                            let element  = document.querySelector(`#sessionDetails`)
                            const newPosition = element.scrollHeight.valueOf() - element.clientHeight
                            element.scrollTo({
                                top: newPosition,
                                behavior: "smooth"
                            })
                        }, 50)
                    } else {
                        console.error("ERROR: unwanted status returned", resp.status)
                    }
                })
                .catch((err) => {
                    console.error("ERROR: searchCoLearners FAILED", err)
                })
            }, 250),
        }
    }
</script>

<style scoped>

</style>
