<template>
    <div class="flex flex-col space-x-3">
        <div class="flex w-full items-center space-x-3">
            <profile-image-with-pop-card v-if="!participant.user.isAnonymous" :prop-user="participant.user"></profile-image-with-pop-card>
            <img v-else class="inline-block rounded-full bg-gray-200 h-8 w-8" style="z-index: 10" :src="participant.user.avatarUrl"
                 alt="" >
            <div class="flex-1 space-y-1">
                <div class="flex items-center justify-between">
                    <h3 class="text-sm font-medium leading-5">{{ participant.user.firstName + " " + participant.user.lastName}}</h3>
                    <div class="ml-2 mt-1 flex justify-end items-start w-1/4">
                        <div v-if="participant.role !== 'presenter'" class="flex items-center">
                            <span v-if="participant.status === 'attending'" class="flex-shrink-0 inline-block px-2 py-0.5 text-teal-800 text-xs leading-4 font-medium  bg-teal-100 rounded-full">Attending</span>
                            <span v-if="participant.status === 'invited'" class="flex-shrink-0 inline-block px-2 py-0.5  text-white text-xs leading-4 font-medium bg-jiruto-flatRed rounded-full">Awaiting</span>
                        </div>
                    </div>
                </div>
                <p v-if="participant.role ==='moderator'" class="text-sm leading-5 text-indigo-600">moderator</p>
            </div>
            <div v-if="canEdit" class="mt-1 inline-block text-left ml-1" >
                <div class="" style="z-index: 100">
                    <button @click.stop="showOptionsMenu = !showOptionsMenu" class="flex items-center text-gray-400 hover:text-gray-600 focus:outline-none active:text-gray-900" aria-label="Options" id="options-menu" aria-haspopup="true" aria-expanded="true">
                        <svg class="h-5 w-5" fill="currentColor" viewBox="0 0 20 20">
                            <path d="M10 6a2 2 0 110-4 2 2 0 010 4zM10 12a2 2 0 110-4 2 2 0 010 4zM10 18a2 2 0 110-4 2 2 0 010 4z" />
                        </svg>
                    </button>
                </div>

                <transition enter-class="transform opacity-0 scale-y-95"
                            enter-active-class="transition ease-out duration-100"
                            enter-to-class="transform opacity-100 scale-y-100"
                            leave-class="transform opacity-100 scale-y-100"
                            leave-active-class="transition ease-in duration-75"
                            leave-to-class="transform opacity-0 scale-y-95">

                    <div v-if="showOptionsMenu && canEdit" v-on-clickaway="closeOptionsMenu" class="origin-top-right right-5 absolute mt-2 w-56 rounded-md shadow-lg" style="z-index: 110">
                        <div class="rounded-md bg-gray-100 shadow-xs">
                            <div class="py-1" role="menu" aria-orientation="vertical" aria-labelledby="options-menu">

                                <button @click="removeParticipant"  class="group flex w-full items-center px-4 py-2 text-sm leading-5 text-gray-700 hover:bg-gray-200 hover:text-gray-900 focus:outline-none active:bg-gray-300 focus:text-gray-900" role="menuitem">
                                    <!-- Heroicon name: pencil-alt -->
                                    <svg class="mr-3 h-5 w-5 text-gray-400 group-hover:text-gray-500 group-active:text-gray-700" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
                                        <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M15 12H9m12 0a9 9 0 11-18 0 9 9 0 0118 0z"></path>
                                    </svg>
                                    <span v-if="participant.user.isAnonymous && participant.role === 'presenter'">Remove Presenter</span>
                                    <span v-else>Remove participant</span>
                                </button>

                                <button v-if="participant.role === 'presenter' && !participant.user.isAnonymous" @click="revokePresentation"  class="group flex w-full items-center px-4 py-2 text-sm leading-5 text-gray-700 hover:bg-gray-200 hover:text-gray-900 focus:outline-none active:bg-gray-300 focus:text-gray-900" role="menuitem">
                                    <!-- Heroicon name: pencil-alt -->
                                    <svg class="mr-3 h-5 w-5 text-gray-400 group-hover:text-gray-500 group-focus:text-gray-500" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
                                        <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M7 12l3-3 3 3 4-4M8 21l4-4 4 4M3 4h18M4 4h16v12a1 1 0 01-1 1H5a1 1 0 01-1-1V4z"></path>
                                    </svg>
                                    Revoke Presentation
                                </button>

                                <button v-if="participant.role === 'participant'" @click="makePresenter"  class="group flex w-full items-center px-4 py-2 text-sm leading-5 text-gray-700 hover:bg-gray-200 hover:text-gray-900 focus:outline-none active:bg-gray-300 focus:text-gray-900" role="menuitem">
                                    <!-- Heroicon name: pencil-alt -->
                                    <svg class="mr-3 h-5 w-5 text-gray-400 group-hover:text-gray-500 group-focus:text-gray-500" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
                                        <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M7 12l3-3 3 3 4-4M8 21l4-4 4 4M3 4h18M4 4h16v12a1 1 0 01-1 1H5a1 1 0 01-1-1V4z"></path>
                                    </svg>
                                    Make Presenter
                                </button>
                            </div>
                        </div>
                    </div>

                </transition>
            </div>

        </div>

        <!--                                        <img class="h-6 w-6 rounded-full" src="https://images.unsplash.com/photo-1517365830460-955ce3ccd263?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=crop&w=256&h=256&q=80" alt="">-->

        <div v-if="canEdit">
            <div v-if="participant.role ==='presenter' && itemType === 'presenter' && participant.showMagicLink === true" class="relative w-10/12 mt-2 rounded-md shadow-sm sm:max-w-xs">
                <button :id="`copied${participant.user.JID}`" v-on-clickaway="hideMagicLink" @click="copyToClipboard" class="absolute inset-y-0 right-0 pl-3 flex items-center text-gray-400 hover:text-gray-600 active:text-gray-900 pointer-cursor">
                    <!-- Heroicon name: search -->
                    <svg class="mr-3 h-4 w-4" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" stroke="currentColor" fill="none">
                        <path  stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M9 5H7a2 2 0 00-2 2v12a2 2 0 002 2h10a2 2 0 002-2V7a2 2 0 00-2-2h-2M9 5a2 2 0 002 2h2a2 2 0 002-2M9 5a2 2 0 012-2h2a2 2 0 012 2"></path>
                    </svg>
                </button>
                <input readonly autocomplete="off" :value="outsidePresenterInvitationLink"
                       class="form-input block w-full pr-9 sm:text-sm sm:leading-5" placeholder="copy link">
            </div>
            <button @click="getMagicToken" v-else-if="participant.user.isAnonymous" class="w-full flex justify-start py-2 text-sm leading-5 border-gray-200 ">
                <span class="text-indigo-500 font-semibold text-sm hover:text-indigo-700 active:text-indigo-900">Get magic link</span>
            </button>
        </div>

    </div>
</template>

<script>
    import tippy from 'tippy.js';
    import 'tippy.js/dist/tippy.css';
    import * as axios from '@/axios-auth'
    import {directive as onClickaway} from 'vue-clickaway'
    import * as _ from 'lodash'
    import ProfileImageWithPopCard from "@/components/ProfileImageWithPopCard";
    export default {
        name: "SessionParticipantItem",
        props: ['sessionID', 'participantProp', 'canEdit', 'itemType'],
        components: {ProfileImageWithPopCard},
        directives:{
            onClickaway
        },
        data(){
            return {
                showOptionsMenu: false,
                tippyCopied: null,
                showSuccessLinkCopy: false,
                participant: {
                    showMagicLink: false,
                },
            }
        },
        created(){
            let newParticipant = _.cloneDeep(this.participantProp)
            if(newParticipant.showMagicLink === null || newParticipant.showMagicLink === undefined){
                newParticipant.showMagicLink = false
            }
            this.participant = newParticipant
        },
        mounted() {
            if(this.participant.showMagicLink){
                this.createTippyInstance()
            }

        },
        computed: {
            outsidePresenterInvitationLink(){
                if(this.canEdit && this.participant.user.magicToken !== null && this.participant.user.magicToken !== ""){
                    return `https://jiruto.com/groupSession/${this.sessionID}?JID=${this.participant.user.JID}&magicToken=${this.participant.user.magicToken}`
                }
                return ""
            }
        },
        methods: {
            // makePresenter(){
            //     console.log("Yooooo, emitting!")
            //     this.$emit("revokePresentation", this.participant)
            //     // this.$emit("makePresenter", this.participant.user)
            // },
            makePresenter(){
                this.$emit("makePresenter", this.participant)
                this.closeOptionsMenu()
            },
            closeOptionsMenu(){
                this.showOptionsMenu = false
            },
            revokePresentation(){
                this.$emit("revokePresentation", this.participant)
                this.closeOptionsMenu()
            },
            removeParticipant(){
                if(this.participant.role === "presenter" && this.participant.user.isAnonymous){
                    this.$emit("removePresenter", this.participant)
                } else {
                    this.$emit("removeParticipant", this.participant)
                }
                this.closeOptionsMenu()
            },
            copyToClipboard(){
                navigator.clipboard.writeText(this.outsidePresenterInvitationLink)
                this.$emit("showSuccessfulLinkCopy")
                this.tippyCopied[0].show()
                setTimeout(() => {
                    if(this.tippyCopied !== null){
                        this.tippyCopied[0].hide()
                    }
                }, 1000)
            },
            hideMagicLink(){
                this.participant.showMagicLink = false
                this.tippyCopied[0].destroy()
            },
            getMagicToken(){
                if(!this.participant.user.isAnonymous){
                    return
                }
                axios.jigo.get(`/v2/community/${this.$store.state.community.communityOwner}/${this.$store.state.community.communityName}/groupSession/${this.sessionID}/presenters/getMagicToken/${this.participant.user.JID}`)
                .then((resp) => {
                    if(resp.status === 200) {
                        this.participant.user.magicToken = resp.data
                        this.showMagicLink()
                    } else {
                        console.error("ERROR: unwanted status returned", resp.status)
                    }
                })
                .catch((err) => {
                    console.error("ERROR: getMagicToken FAILED", err)
                })
            },
            createTippyInstance(){
                setTimeout(() => {
                    this.tippyCopied = tippy(`#copied${this.participant.user.JID}`, {
                        content: 'Link Copied',
                        trigger: 'manual',
                    });
                }, 100)
            },
            showMagicLink(){
                this.participant.showMagicLink = true
                this.createTippyInstance()

            }

        }

    }
</script>

<style scoped>

</style>
