<template>
    <section>
        <div v-if="participants.length !== 0" class="pt-6 pb-2 mt-5">
            <h2 class="text-sm leading-5 font-semibold">More participants</h2>
        </div>
        <div>
            <div >
                <transition-group name="participants" tag="ul" class="divide-y divide-gray-200"
                                  enter-class="transform opacity-0 scale-y-95"
                                  enter-active-class="transition ease-out duration-700"
                                  enter-to-class="transform opacity-100 scale-y-100"
                                  leave-class="transform opacity-100 scale-y-100"
                                  leave-active-class="transition ease-in duration-700"
                                  leave-to-class="transform opacity-0 scale-y-95"
                >
                    <li v-for="(participant) in participants " :key="participant.user.JID" class="py-4 px-1">
                        <SessionParticipantItem :participant-prop="participant" :canEdit="canEdit"
                                                :sessionID="sessionID" item-type="participant" @makePresenter="makePresenter"
                                                @removeParticipant="removeParticipant"/>
                    </li>
                </transition-group>

                <!-- More items... -->
            </div>
            <!--                            <div class="py-4 text-sm leading-5 border-t border-gray-200">-->
            <!--                                <a href="#" class="text-indigo-600 font-semibold hover:text-indigo-900">View all activity &rarr;</a>-->
            <!--                            </div>-->
        </div>
        <div v-if="canEdit && participants.length < 7 && session.groupSession.sessionType === 'private'" class="mb-6 sm:mt-2 sm:gap-4 sm:items-start sm:border-gray-200 sm:py-2">
            <div class="flex flex-col">
                <label for="searchCoLearners" class="block text-sm font-medium leading-5 text-gray-700 sm:mt-px sm:pt-2">
                    Invite Participants
                </label>
                <p class="text-xs text-gray-500">* you must be colearners</p>
            </div>
            <div class="mt-2 sm:mt-1 sm:col-span-2">
                <div class="">
                    <label for="searchCoLearners" class="sr-only">Search colearners</label>
                    <div class="relative rounded-md shadow-sm sm:max-w-xs">
                        <div class="absolute inset-y-0 left-0 pl-3 flex items-center pointer-events-none">
                            <!-- Heroicon name: search -->
                            <svg class="mr-3 h-4 w-4 text-gray-400" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor">
                                <path fill-rule="evenodd" d="M8 4a4 4 0 100 8 4 4 0 000-8zM2 8a6 6 0 1110.89 3.476l4.817 4.817a1 1 0 01-1.414 1.414l-4.816-4.816A6 6 0 012 8z" clip-rule="evenodd" />
                            </svg>
                        </div>
                        <input type="text" autocomplete="off" v-model="coLearnerSearchTerm" id="searchCoLearners" @focus="searchCoLearners" @input="searchCoLearners"
                               class="w-full pl-9 sm:text-sm sm:leading-5 block border-gray-300 rounded-md focus:ring-indigo-500 focus:border-indigo-500 border w-full rounded-md transition duration-150 ease-in-out sm:text-sm sm:leading-5" placeholder="Search CoLearners">
                    </div>
                    <div class="mb-10">
                        <userSelection v-if="showCoLearnerSelection" :users="coLearnersList" @close="showCoLearnerSelection = false"
                                       @userSelected="inviteParticipant"  :open="showCoLearnerSelection" class="z-50 w-full "/>
                    </div>
                </div>
            </div>
        </div>
    </section>

</template>

<script>
    import * as _ from 'lodash'
    import * as axios from '@/axios-auth'
    import UserSelection from "@/components/UserSelection";
    import SessionParticipantItem from "@/components/SessionParticipantItem";
    export default {
        name: "GroupSessionPageParticipantsSection",
        components: {SessionParticipantItem, UserSelection},
        props: ['sessionID', 'session', 'participantsProp', 'canEdit', 'communityOwner', 'communityName', 'inviteesAndAttendees'],
        data(){
            return {
                participants: [],
                showCoLearnerSelection: false,
                coLearnersList: [],
                coLearnerSearchTerm: "",

            }
        },
        created(){
            this.participants = _.cloneDeep(this.participantsProp)
        },
        watch: {
            participantsProp(){
                this.participants = _.cloneDeep(this.participantsProp)
            }
        },
        computed: {
            communityNameForUrl(){
                return this.communityName.replace(/\s/g, "-")
            },
        },
        methods: {
            isAParticipant(inviteeID){
                const index =  _.findIndex(this.inviteesAndAttendees, (participant) => {
                    return participant.user.JID === inviteeID
                });
                return index !== -1
            },
            removeParticipant(participant){
                this.$emit("removeParticipant", participant)
            },
            inviteParticipant(invitee){
                if (this.isAParticipant(invitee.JID)){
                    console.log("Already a participant")
                    return
                }
                const inviteeObject = {
                    status: "invited",
                    role: "participant",
                    user: invitee
                }
                // this.addParticipantLocally(inviteeObject)
                this.$emit("addParticipantLocally", inviteeObject)
                axios.jigo.post(`/v2/groupSession/${this.sessionID}/invitee/${invitee.username}/invite`,{validateStatus: (status) => {
                        return status < 500
                    }})
                .then((resp) => {
                    if(resp.status !== 200) {
                        // this.removeParticipantLocally(inviteeObject)
                        this.$emit("removeParticipantLocally", inviteeObject)
                        this.showAddParticipantAlertNotification = true
                        setTimeout(() => this.showAddParticipantAlertNotification = false, 5000)
                        console.error("ERROR: unwanted status returned", resp.status)
                    }
                })
                .catch((err) => {
                    // this.removeParticipantLocally(inviteeObject)
                    this.$emit("removeParticipantLocally", inviteeObject)
                    this.showAddParticipantAlertNotification = true
                    setTimeout(() => this.showAddParticipantAlertNotification = false, 5000)
                    console.error("ERROR: inviteParticipant FAILED", err)
                })
            },
            makePresenter(presenter){

                this.$emit("addPresenterLocally", presenter)

                axios.jigo.post(`/v2/community/${this.communityOwner}/${this.communityNameForUrl}/groupSession/${this.sessionID}/presenters/add/${presenter.user.JID}`)
                .then((resp) => {
                    if(resp.status !== 200) {
                        this.$emit("removePresenterLocally", presenter)
                        console.error("ERROR: unwanted status returned", resp.status)
                    }
                })
                .catch((err) => {
                    this.$emit("removePresenterLocally", presenter)
                    console.error("ERROR: addPresenter FAILED", err)
                })
            },
            searchCoLearners: _.debounce(function ()  {
                if(this.coLearnerSearchTerm.trim() === "") {
                    return
                }
                axios.jigo.get(`/v2/user/coLearners/search`, {params: {q: this.coLearnerSearchTerm}})
                .then((resp) => {
                    if(resp.status === 200) {
                        this.coLearnersList = resp.data
                        this.showCoLearnerSelection = true
                        setTimeout(() => {
                            let element  = document.querySelector(`#sessionDetails`)
                            const newPosition = element.scrollHeight.valueOf() - element.clientHeight
                            element.scrollTo({
                                top: newPosition,
                                behavior: "smooth"
                            })
                        }, 50)
                    } else {
                        console.error("ERROR: unwanted status returned", resp.status)
                    }
                })
                .catch((err) => {
                    console.error("ERROR: searchCoLearners FAILED", err)
                })
            }, 250),
        }
    }
</script>

<style scoped>

</style>
