<template>
    <div class="block w-full">
        <div class="px-1">
            <div class="">
                <div class="">
                    <div class="sm:gap-4 sm:items-start sm:border-gray-200 sm:pt-5">
                        <label for="topic" class="block text-sm font-medium leading-5 text-gray-700">Topic</label>
                        <div class="mt-1 sm:mt-0">
                            <div class="max-w-lg rounded-md shadow-sm sm:max-w-xs">
                                <input v-model="newDetails.topic" id="topic"
                                       @focusin="$v.newDetails.topic.$reset()"
                                       @focusout="$v.newDetails.topic.$touch()"
                                       placeholder="give your session a topic"
                                       class="form-input block w-full transition duration-150 ease-in-out sm:text-sm sm:leading-5"
                                       :class="{'border-red-500 placeholder-red-500': $v.newDetails.topic.$error}">
                            </div>
                        </div>
                    </div>

                    <div class="sm:gap-4 sm:items-start sm:border-t sm:border-gray-200 sm:pt-5">
                        <label for="agenda" class="block text-sm font-medium leading-5 text-gray-700">Agenda</label>
                        <div class="mt-1 sm:mt-0">
                            <div class="max-w-lg flex rounded-md shadow-sm">
                                <textarea v-model="newDetails.agenda" id="agenda" rows="7" class="form-textarea block w-full transition duration-150 ease-in-out sm:text-sm sm:leading-5" placeholder="What's your agenda?"></textarea>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div class="sm:gap-4 sm:items-start sm:border-t sm:border-gray-200 sm:pt-5 z-200">
                <p class="block text-sm font-medium leading-5 text-gray-700 sm:mt-px sm:pt-2">
                    Date and Time
                </p>
                <div class="mt-1 sm:mt-0 z-200">
                    <div>
                        <vc-date-picker :input-debounce="500" :is24hr="true" v-model="newDetails.startsAt"
                                        mode="dateTime" timezone="" :min-date="new Date()" >
                            <template v-slot="{ inputValue, inputEvents }">
                                <input
                                        class="bg-white border px-2 py-1 rounded"
                                        :value="inputValue"
                                        v-on="inputEvents"
                                        autocomplete="off"
                                        @focusin="$v.newDetails.startsAt.$reset()"
                                        @focusout="$v.newDetails.startsAt.$touch()"
                                        placeholder="choose a date"
                                        :class="{'border-red-500 placeholder-red-500' : $v.newDetails.startsAt.$error}"
                                />
                            </template>
                        </vc-date-picker>
                    </div>
                </div>
            </div>
        </div>
        <div class="mt-8 border-t border-gray-200 pt-5">
            <div class="flex justify-end">
                            <span class="inline-flex rounded-md shadow-sm">
                                <button @click="cancelDetailsEdit" type="button" class="py-2 px-4 border border-gray-300 rounded-md text-sm leading-5 font-medium text-gray-700 hover:text-gray-500 focus:outline-none focus:border-blue-300 focus:shadow-outline-blue active:bg-gray-50 active:text-gray-800 transition duration-150 ease-in-out">
                                    Cancel
                                </button>
                            </span>
                <span class="ml-3 inline-flex rounded-md shadow-sm">
                                <button @click="saveNewSessionDetails" type="submit" class="inline-flex justify-center py-2 px-4 border border-transparent text-sm leading-5 font-medium rounded-md text-white bg-indigo-600 hover:bg-indigo-500 focus:outline-none focus:border-indigo-700 focus:shadow-outline-indigo active:bg-indigo-700 transition duration-150 ease-in-out">
                                    Save
                                </button>
                            </span>
            </div>
        </div>
    </div>

</template>

<script>
    import { required } from 'vuelidate/lib/validators'
    import * as axios from '@/axios-auth'
    export default {
        name: "EditGroupSessionDetailsForm",
        props: ['topic', 'agenda', 'startsAt', 'sessionID'],
        data() {
            return {
                newDetails: {
                    topic: null,
                    agenda: null,
                    startsAt: null,
                },
            }
        },
        validations: {
            newDetails: {
                topic: {
                    required,
                },
                startsAt: {
                    required
                },
            },
        },
        created() {
            this.newDetails.topic = this.topic
            this.newDetails.agenda = this.agenda
            this.newDetails.startsAt = this.startsAt
        },
        methods: {
            cancelDetailsEdit(){
                this.$emit("cancelDetailsEdit")
            },
            saveNewSessionDetails(){
                this.$v.$touch()
                if(this.$v.$invalid){
                    return
                }
                let loader = this.$loading.show({
                    onCancel: this.loaderCanceled,
                });
                axios.jigo.put(`/v2/groupSession/${this.sessionID}/updateDetails`, this.newDetails,{validateStatus: (status) => {
                        return status < 500
                    }})
                .then((resp) => {
                    if(resp.status === 200) {
                        loader.hide()
                        this.$emit("newGroupSessionDetailsSaved", this.newDetails)
                    } else {
                        loader.hide()
                        console.error("ERROR: unwanted status returned", resp.status)
                    }
                })
                .catch((err) => {
                    loader.hide()
                    console.error("ERROR: saveNewSessionDetails FAILED", err)
                })
            },
        }
    }
</script>

<style scoped>

</style>
